/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import SpinGameColors from '../includes/SpinGameColors';
import { __, wait, decode, encode, isMobile, getElement, Game, sendNotfication, forceSatoshiFormat } from "../../../../Helper";
import SpinnerSVG from './ring.svg';
import storage from "../../../../Storage";
import Engine from "../Engine";
import socket from "../../../../Socket";
import C from "../../../../Constant";

let cFont = null;

class CanvasSpin extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: Engine,
            connecting: true,
            status: '',
            timer: '',
            inPlay: true,
            mustSpin: false,
            prizeNumber: 0,
            rotation: 0,
            spinning: false,
            game_text: '',
            force: false,
            pointerColor: '#74787b',
            bet_amount: 0,
            channel: '',
            credit: false,
            different: [],
            credits: {},
            won: 0,
            selectionType: 'middle',
        };
        this.spin = this.spin.bind(this);
        this.startSpin = this.startSpin.bind(this);
        this.stopSpin = this.stopSpin.bind(this);
        this.updateCredit = this.updateCredit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;
        this.setState({ width: window.innerWidth });
        // this.checkConnection();

        this.state.engine.trigger.on("finish_spin", data => this.finish_spin(data));
        this.state.engine.trigger.on("waiting_spin", data => this.waiting_spin(data));
        this.state.engine.trigger.on("started_spin", data => this.started_spin(data));
        this.state.engine.trigger.on("busted_spin", data => this.busted_spin(data));
        this.state.engine.trigger.on("game_status_spin", data => this.status_spin(data));
        this.state.engine.trigger.on("history_spin", data => this.sync_spin(data));

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkConnection = () => {
        setInterval(() => {
            if (navigator.onLine === false && this.state.status === 'started_spin') {
                this.setState({ connecting: true });
            } else {
                this.setState({ connecting: false });
            }
        }, 2000);
    };
    finish_spin(data) {
        if (this._isMounted) {
            let { name } = data;
        }
    }

    sync_spin(data) {
        if (this._isMounted) {
            let { channel } = data;
            this.setState({ channel: channel });
        }
    }

    waiting_spin(data) {
        if (this._isMounted) {
            const { time } = data;
            let countdown = time;

            this.setState({
                status: 'waiting_spin',
                inPlay: false,
                game_text: `Next round in ${(countdown / 1000).toFixed(1)}s`,
                countdownComplete: false,
                bet_amount: 0
            });

            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }

            this.countdownInterval = setInterval(() => {
                countdown -= 100;

                this.setState({
                    game_text: `Next round in ${(countdown / 1000).toFixed(1)}s`
                });

                if (this.state.status == 'started_spin') {
                    clearInterval(this.countdownInterval);
                    this.setState({
                        game_text: 'Game playing...',
                        countdownComplete: true
                    });
                }
            }, 100);

            this.stopSpin();
        }
    }

    started_spin(data) {
        if (this._isMounted) {
            let { time, md5 } = data;
            let { channel } = this.state;

            let { totalPlacedBetAmount } = this.props;

            this.setState({
                status: 'started_spin',
                inPlay: true,
                game_text: 'Game playing...',
                pointerColor: '#74787b',
                spinning: true,
                bet_amount: totalPlacedBetAmount,
                channel: channel
            });

            this.spinStartTime = Date.now(); 
            this.spin(); 
        }
    }

    busted_spin(data) {
        if (this._isMounted) {
            const { time, color } = data;
            const { channel, won } = this.state;
            this.stopSpin();

            let pointerColorD = '';

            switch (color) {
                case 'GREY':
                    pointerColorD = '#D2DEF8';
                    break;
                case 'PURPLE':
                    pointerColorD = '#85F';
                    break;
                case 'ORANGE':
                    pointerColorD = '#FF7E2E';
                    break;
                case 'GREEN':
                    pointerColorD = '#15ff73';
                    break;
                default:
                    console.error("Color not found:", color);
                    return;
            }
            

            this.setState({
                status: 'busted_spin',
                timer: time,
                inPlay: false,
                game_text: 'Round Over',
                pointerColor: pointerColorD,
                bet_amount: 0
            });

            // console.log("Busted color:", color);
        }

    }

    status_spin(data) {
        if (this._isMounted) {
            let { amount, time, md5, status } = data;
            this.setState({ status: status, timer: time, connecting: false });

            switch (status) {
                case 'started_spin':
                    this.spin();
                    break;
                case 'waiting_spin':
                    this.stopSpin();
                    break;
                case 'busted_spin':
                    this.stopSpin();
                    break;
            }
        }
    }

    spin() {
        if (this._isMounted && this.state.spinning) {
            const { channel, selectionType } = this.state;
            const targetRotation = this.getRotationForChannel(channel, selectionType);
            const totalDuration = 15000;
            const elapsedTime = Date.now() - this.spinStartTime;

            const progress = Math.min(elapsedTime / totalDuration, 1);
            const easing = this.easeOutCubic(progress);

            const currentRotation = easing * targetRotation;

            const { color: hexColor } = this.getColorByChannel(channel);

            this.setState({ rotation: currentRotation }, () => {
                if (progress < 1) {
                    requestAnimationFrame(() => this.spin());
                } else {
                    this.setState({
                        status: 'busted_spin',
                        game_text: 'Round Over',
                        pointerColor: hexColor,
                        selectionType: this.getNextSelectionType(selectionType)
                    });
                }
            });
        }
    }


    getNextSelectionType(currentType) {
        if (currentType === 'first') {
            return 'second';
        } else if (currentType === 'second') {
            return 'middle';
        } else if (currentType === 'middle') {
            return 'second-last';
        } else {
            return 'first';
        }
    }


    easeOutCubic(t) {
        return 1 - Math.pow(1 - t, 3);
    }

    getRotationForChannel(channel, selectionType) {
        const segments = 32;
        const segmentAngle = 360 / segments;

        const { color: hexColor } = this.getColorByChannel(channel);

        const svgColors = [
            { "index": 0, "color": "#15FF73", "name": "Green" },
            { "index": 1, "color": "#FF7E2E", "name": "Orange" },
            { "index": 2, "color": "#D2DEF8", "name": "Grey" },
            { "index": 3, "color": "#85F", "name": "Purple" },
            { "index": 4, "color": "#D2DEF8", "name": "Grey" },
            { "index": 5, "color": "#85F", "name": "Purple" },
            { "index": 6, "color": "#D2DEF8", "name": "Grey" },
            { "index": 7, "color": "#85F", "name": "Purple" },
            { "index": 8, "color": "#D2DEF8", "name": "Grey" },
            { "index": 9, "color": "#FF7E2E", "name": "Orange" },
            { "index": 10, "color": "#D2DEF8", "name": "Grey" },
            { "index": 11, "color": "#FF7E2E", "name": "Orange" },
            { "index": 12, "color": "#D2DEF8", "name": "Grey" },
            { "index": 13, "color": "#85F", "name": "Purple" },
            { "index": 14, "color": "#D2DEF8", "name": "Grey" },
            { "index": 15, "color": "#85F", "name": "Purple" },
            { "index": 16, "color": "#D2DEF8", "name": "Grey" },
            { "index": 17, "color": "#85F", "name": "Purple" },
            { "index": 18, "color": "#D2DEF8", "name": "Grey" },
            { "index": 19, "color": "#85F", "name": "Purple" },
            { "index": 20, "color": "#D2DEF8", "name": "Grey" },
            { "index": 21, "color": "#FF7E2E", "name": "Orange" },
            { "index": 22, "color": "#D2DEF8", "name": "Grey" },
            { "index": 23, "color": "#FF7E2E", "name": "Orange" },
            { "index": 24, "color": "#D2DEF8", "name": "Grey" },
            { "index": 25, "color": "#85F", "name": "Purple" },
            { "index": 26, "color": "#D2DEF8", "name": "Grey" },
            { "index": 27, "color": "#85F", "name": "Purple" },
            { "index": 28, "color": "#D2DEF8", "name": "Grey" },
            { "index": 29, "color": "#85F", "name": "Purple" },
            { "index": 30, "color": "#D2DEF8", "name": "Grey" },
            { "index": 31, "color": "#FF7E2E", "name": "Orange" }
        ];

        const matchingSegments = svgColors
            .filter(segment => segment.color === hexColor)
            .map(segment => segment.index);

        let selectedIndex;
    
        if (matchingSegments.length > 0) {
            if (hexColor === '#15FF73') {
                selectedIndex = 0; 
            } else {
                switch (selectionType) {
                    case 'first':
                        selectedIndex = matchingSegments[0];
                        break;
                    case 'middle':
                        if (matchingSegments.length > 0) {
                            selectedIndex = matchingSegments[Math.floor(matchingSegments.length / 2)];
                        }
                        break;
                    case 'second-last':
                        if (matchingSegments.length > 1) {
                            selectedIndex = matchingSegments[matchingSegments.length - 2];
                        }
                        break;
                    case 'second':
                        if (matchingSegments.length > 1) {
                            selectedIndex = matchingSegments[1];
                        }
                        break;
                    default:
                        console.error("Invalid selectionType:", selectionType);
                        break;
                }
            }
    
                const pointerOffset = segmentAngle / 2 - 6; 
                const targetAngle = selectedIndex * segmentAngle + pointerOffset;
                const targetRotation = 360 * 5 + targetAngle;
    
                return targetRotation;
            
        }

        console.error("No matching segment found for color:", hexColor);
        return 0;
    }
    

    getSegmentByIndex(index) {
        const svgColors = [
            { "index": 0, "color": "#15FF73", "name": "Green" },
            { "index": 1, "color": "#FF7E2E", "name": "Orange" },
            { "index": 2, "color": "#D2DEF8", "name": "Grey" },
            { "index": 3, "color": "#85F", "name": "Purple" },
            { "index": 4, "color": "#D2DEF8", "name": "Grey" },
            { "index": 5, "color": "#85F", "name": "Purple" },
            { "index": 6, "color": "#D2DEF8", "name": "Grey" },
            { "index": 7, "color": "#85F", "name": "Purple" },
            { "index": 8, "color": "#D2DEF8", "name": "Grey" },
            { "index": 9, "color": "#FF7E2E", "name": "Orange" },
            { "index": 10, "color": "#D2DEF8", "name": "Grey" },
            { "index": 11, "color": "#FF7E2E", "name": "Orange" },
            { "index": 12, "color": "#D2DEF8", "name": "Grey" },
            { "index": 13, "color": "#85F", "name": "Purple" },
            { "index": 14, "color": "#D2DEF8", "name": "Grey" },
            { "index": 15, "color": "#85F", "name": "Purple" },
            { "index": 16, "color": "#D2DEF8", "name": "Grey" },
            { "index": 17, "color": "#85F", "name": "Purple" },
            { "index": 18, "color": "#D2DEF8", "name": "Grey" },
            { "index": 19, "color": "#85F", "name": "Purple" },
            { "index": 20, "color": "#D2DEF8", "name": "Grey" },
            { "index": 21, "color": "#FF7E2E", "name": "Orange" },
            { "index": 22, "color": "#D2DEF8", "name": "Grey" },
            { "index": 23, "color": "#FF7E2E", "name": "Orange" },
            { "index": 24, "color": "#D2DEF8", "name": "Grey" },
            { "index": 25, "color": "#85F", "name": "Purple" },
            { "index": 26, "color": "#D2DEF8", "name": "Grey" },
            { "index": 27, "color": "#85F", "name": "Purple" },
            { "index": 28, "color": "#D2DEF8", "name": "Grey" },
            { "index": 29, "color": "#85F", "name": "Purple" },
            { "index": 30, "color": "#D2DEF8", "name": "Grey" },
            { "index": 31, "color": "#FF7E2E", "name": "Orange" }
        ];

        return svgColors[index];
    }

    startSpin() {
        this.setState({ spinning: true }, () => {
            requestAnimationFrame(this.spin);
        });
    }

    stopSpin() {
        this.setState({ spinning: false });
    }

    getColorByChannel(channel) {
        const colorMap = {
            1: { color: '#D2DEF8', name: 'Grey' },
            2: { color: '#85F', name: 'Purple' },
            3: { color: '#FF7E2E', name: 'Orange' },
            4: { color: '#15FF73', name: 'Green' }
        };

        return colorMap[channel] || { color: '#D2DEF8', name: 'Grey' };
    }


    getCredit(data) {
        let { credit } = data;

        this.setState({ credits: credit });

        let currentCoin = 'kshs';
        let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
        this.setState({ credit: currentCredit });
    }


    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success'
            };
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger'
            };
        }

        let currentCredit = forceSatoshiFormat(this.state.credits[coin]);
        this.setState({
            credit: currentCredit,
            different: [...this.state.different, arr]
        });

    }


    render() {
        let check = {};
        let { connecting, inPlay, rotation, spinning } = this.state;

        if (this.state.connecting) {
            check = { display: "none" };
        }

        return (
            <Card className="no-shadow mb-0 game-wrapper-card rounded">
                <Card.Body className={`bg-body text-center rounded no-shadow pt-2 static-snow p-0 ${inPlay ? 'game-playing' : 'game-busted'}`} id="canvasPart">
                    <div className="game-holder">
                        <>
                            <Card className="mb-1 bg-transparent">
                                <Card.Body className="pt-0" style={{ padding: 0 }}>
                                    <div className="history_">
                                        <SpinGameColors game="spin" t={this.props.t} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </>
                    </div>

                    <div className="container-2">
                        <div className="roulette-container-2">
                            <div className="pointer-2" style={{ borderBottomColor: this.state.pointerColor }}></div>
                            <img
                                src={SpinnerSVG}
                                className="roulette-svg-2"
                                style={{ transform: `rotate(${rotation}deg)` }}
                                alt="Spinning Wheel"
                            />
                            <div className="center-text">
                                {this.state.inPlay ?
                                    <>
                                        <div className="bet-text-2">Bet Amount <br />
                                            <span className='text-success'>KES {(this.state.bet_amount).toFixed(2)}</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="bet-text-2">{this.state.game_text}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                </Card.Body>
            </Card>
        );
    }
}

CanvasSpin.propTypes = {
    profit: PropTypes.string,
    im_in_game: PropTypes.bool
};

const mapStateToProps = state => ({
    profit: state.items.profit,
    im_in_game: state.items.im_in_game
});

export default connect(mapStateToProps, {})(CanvasSpin);
